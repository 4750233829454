import React from 'react';
import './Checkout.css';
import CheckoutProduct from './CheckoutProduct';
import { useStateValue } from './StateProvider';
import Subtotal from "./Subtotal";

function Checkout() {

    const [{ basket }, dispatch] = useStateValue();


    return (
        <div className="checkout">
           <div className="checkout__left">
               <img className="checkout__ad" src="https://www.junglescout.com/wp-content/uploads/2020/05/Prime-day-banner.png" alt="" />
                <div>
                    <h2 className="checkout__title">
                        Your Shopping Basket
                    </h2>

                    {basket.map(item => (
                     <CheckoutProduct
                      id={item.id}
                      title={item.title}
                      image={item.image}
                      price={item.price}
                      rating={item.rating}
                      quantity={item.quantity}
                     />
                    ))}

                </div>
            </div>
            <div className="checkout__right">
                <Subtotal />
            </div>
        </div>
    )
}

export default Checkout
